<template>
  <SCTable
    class="mt-6"
    layout="auto"
    :headers="tableHeaders"
    :items="filteredCollaborators"
    :itemName="$t('itemName')"
    :shadowed="false"
  >
    <template #item.avatar="{ item }">
      <div class="flex place-items-center items-center flex-grow">
        <CollaboratorPortrait
          :item="collaboratorPortraitItem(item)"
        />
      </div>
    </template>

    <template #item.tfa_status="{ item }">
      {{ show2FAStatus(item.tfa_status) }}
    </template>

    <template #item.apps="{ item }">
      <div
        :ref="`appDropdown${item.id}`"
        class="bg-scale-0 w-24"
      >
        <button
          class="w-full bg-scale-2 rounded py-1 px-2 flex justify-between items-center"
          @click="setOpenCollaborator(item)"
        >
          <span class="text-base font-sans">{{
            $tc("collaborators_table.columns.app", {
              count: item.apps.length,
            })
          }}</span>
          <ShortArrowBottom
            :style="`rotate: ${openCollaborator === item ? 180 : 0}deg;`"
          />
        </button>
        <CenteredDropdownMenu
          :inputPlaceholder="$t('dropdown.filter')"
          :items="item.apps"
          :isOpen="openCollaborator === item"
          callerWidth="w-24"
          @toggle-action="openAppCollaborators"
        />
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import ShortArrowBottom from "@/components/atoms/glyphs/ShortArrowBottom.vue";
import CenteredDropdownMenu from "@/components/molecules/dropdowns/CenteredDropdownMenu.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import CollaboratorPortrait from "@/components/parts/appSettings/collaboratorsSettings/CollaboratorPortrait.vue";

export default defineComponent({
  name: "CollaboratorTable",
  components: {
    CollaboratorPortrait,
    SCTable,
    CenteredDropdownMenu,
    ShortArrowBottom,
  },
  props: {
    filteredCollaborators: {
      type: Array,
      required: true,
    },
  },
  emits: ["openAppCollaborators"],
  data() {
    return {
      openCollaborator: null,
      open: false,
      tableHeaders: [
        { text: "", value: "avatar", class: "w-14" },
        {
          text: this.$t("collaborators_table.headers.username"),
          value: "username",
        },
        {
          text: this.$t("collaborators_table.headers.email"),
          value: "email",
          class: "hidden lg:block h-8 pt-1.5",
          itemsClass: "hidden lg:block h-12 pt-3",
        },
        {
          text: this.$t("collaborators_table.headers.2fa"),
          value: "tfa_status",
        },
        {
          text: this.$t("collaborators_table.headers.apps"),
          value: "apps",
        },
      ],
    };
  },
  computed: {
    collaboratorCount() {
      const count = this.app.collaborators.length;
      const label = this.$t(count === 1 ? "collaborator" : "collaborators");
      return `${count} ${label}`;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    openAppCollaborators(item) {
      this.$emit("openAppCollaborators", item);
    },
    collaboratorPortraitItem(collaborator) {
      return {
        username: collaborator.username,
        status: collaborator.status,
        email: collaborator.email,
        statusHidden: true,
      };
    },
    setOpenCollaborator(collaborator) {
      this.openCollaborator =
        this.openCollaborator === collaborator ? null : collaborator;
    },
    handleClickOutside(event) {
      if (!this.openCollaborator) return;

      const ref = this.getDropdownRef();

      if (this.isClickOutside(ref, event.target)) {
        this.openCollaborator = null;
      }
    },
    isClickOutside(ref, target) {
      if (!ref) return true;

      if (ref.$el) {
        return !ref.$el.contains(target);
      }

      return !ref.contains(target);
    },
    getDropdownRef() {
      return this.$refs[`appDropdown${this.openCollaborator.id}`];
    },
    show2FAStatus(tfa_status) {
      if (tfa_status === null) {
        return;
      }

      return this.$t(`collaborators_table.2fa_values.${tfa_status}`);
    },
  },
});
</script>

<i18n>
en:
  collaborators_table:
    headers:
      username: "Username"
      email: "Email"
      apps: "Assigned to"
      2fa: "2FA"
    columns:
      app: "0 app | 1 app | {count} apps"
    2fa_values:
      true: "Enabled"
      false: "Disabled"
  dropdown:
    filter: "Filter apps..."
  itemName: "collaborator"

fr:
  collaborators_table:
    headers:
      username: "Nom d’utilisateur"
      email: "Email"
      apps: "Assigné à"
      2fa: "2FA"
    columns:
      app: "0 app | 1 app | {count} apps"
    2fa_values:
      true: "Activée"
      false: "Désactivée"
  dropdown:
    filter: "Filtrer les apps..."
  itemName: "collaborateur"
</i18n>
