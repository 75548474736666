<template>
  <span
    class="inline-block relative"
    :title="title"
  >
    <Portrait :user="item" />
    <span
      v-if="showStatus"
      style="top: -4px; right: -4px"
      class="absolute bg-color-1block h-2 w-2 rounded-full ring-2 ring-scale-0"
      :class="{
        'bg-scale-4': item.status !== 'accepted',
      }"
    />
  </span>
</template>
<script>
import { defineComponent } from "vue";

import Portrait from "@/components/atoms/graphics/Portrait.vue";

export default defineComponent({
  name: "CollaboratorPortrait",
  components: { Portrait },
  props: {
    item: Object,
    isOwner: Boolean,
  },
  computed: {
    showStatus() {
      if (this.isOwner || this.item.statusHidden) {
        return false;
      }
      return this.item.status !== "accepted";
    },
    title() {
      const title = this.item.username + "\n" + this.item.email;
      if (this.isOwner || this.item.statusHidden) {
        return title;
      }
      if (this.item.status === "accepted") {
        return title + "\n" + this.$t("accepted");
      }
      return title + "\n" + this.$t("pending");
    },
  },
});
</script>

<i18n>
en:
  pending: "pending invitation"
  accepted: "accepted invitation"
fr:
  pending: "invitation en cours"
  accepted: "invitation acceptée"
</i18n>
